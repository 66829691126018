import React from 'react';

//import Layout from '../components/layout';
import LayoutP from '../components/layoutP';
import ProdCard from '../components/prodCard';
import SEO from '../components/seo';
//import bpIcon from '../images/bp.svg';
//import zupIcon from '../images/zup.svg';
import prods from '../content/prods.json';

//import dogIllustration from '../images/dog-illustration.svg';

// const prods = [
//   {
//     title: '1С:Бухгалтерия 8',
//     imgUrl: '',
//     description:
//       'Самая популярная бухгалтерская программа. Высокий уровень автоматизации учета и отчетности в соответствии с законодательством и потребностями бизнеса.'
//   }
// ];

function ProductsPage() {
  //console.dir(prods);
  // const prds = JSON.parse(prods);
  // console.log(prds);
  return (
    <LayoutP>
      <SEO
        keywords={[
          `1С`,
          `Программы 1С`,
          `О компании`,
          `софтмакс`,
          `1с Пыть-Ях`
        ]}
        title='Продукты'
      />

      <section className=' text-center p-2 '>
        <h2 className='my-3 text-2xl uppercase'>
          Лучшие программы 1С для малого бизнеса
        </h2>
        <div className='flex flex-wrap '>
          {prods.map(prod => {
            return <ProdCard key={prod.title} card={prod} />;
          })}
        </div>
      </section>
    </LayoutP>
  );
}

export default ProductsPage;
