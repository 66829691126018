import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ProdCard = ({ card }) => {
  return (
    <Link
      to={card.path}
      className='sm:w-1/2 md:w-1/3 lg:w-1/4 mx-2 my-2 bg-white  p-4 
      rounded shadow-xs hover:shadow-lg flex flex-auto flex-col 
       md:transform hover:-translate-y-1'
    >
      <div className='flex items-center '>
        <img
          className='w-12 h-12 border border-gray-500 rounded-lg'
          src={card.imgUrl}
          alt={card.title}
        />
        <h4 className=' ml-4 font-bold text-left'>{card.title}</h4>
      </div>
      <p className='text-left mt-3 text-sm'>{card.description}</p>
    </Link>
  );
};

ProdCard.propTypes = {
  card: PropTypes.object.isRequired,
};

export default ProdCard;
